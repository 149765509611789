import React, { useRef, useEffect, useState } from "react";
import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
import "mapbox-gl/dist/mapbox-gl.css";

const App = () => {
  // const [locationSearchValue, setLocationSearchValue] = useState("");
  const [locationSearchValue2, setLocationSearchValue2] = useState("");
  const [destinationSearchValue, setDestinationSearchValue] = useState("");
  const [directions, setDirections] = useState("");
  const [travelMode, setTravelMode] = useState("Driving");

  mapboxgl.accessToken =
    "pk.eyJ1IjoiYmVuamktZGV2ZWxvcHMiLCJhIjoiY2t6MXdrMTJsMXFwcDJvbzJ3ZDhpb3F0eiJ9.p_kVhSZn8AO2Hl2YFeD7VQ";

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-84.2807);
  const [lat, setLat] = useState(30.4383);
  const [zoom, setZoom] = useState(13);
  // const handleLocationSearch = (
  //   event: React.KeyboardEvent<HTMLInputElement>
  // ) => {
  //   console.log(locationSearchValue);
  // };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v9",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.addControl(
      new MapboxDirections({
        accessToken: mapboxgl.accessToken,
        unit: "metric",
        profile: "mapbox/cycling",
      }),
      "top-left"
    );
  });
  return (
    <div className="App">
      <header className="App-header">Worthwhile</header>
      <div className="container inputs">
        <div className="container search-bars">
          <div className="container location-search" id="location-search">
            {/* <input
              className="location-search-input"
              onChange={(e) => {
                setLocationSearchValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleLocationSearch(e);
                }
              }}
              placeholder="choose a location..."
            ></input> */}
            <div ref={mapContainer} className="map-container" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
